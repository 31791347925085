<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-11 15:46:37
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-03-14 18:41:18
 * @FilePath: \official_website\src\mobile\pages\products\details\Patholog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 远程病理会诊系统  --> 
<template>
  <div id="patholog">
    <Header></Header>
    <div class="patholog-img">
      <Breadcrumb
        :productType="productType"
        :productName="productName"
      ></Breadcrumb>
      <img src="@/assets/image/patholog/pathology.png" alt="" />
      <div class="illustrate-box">
        <div class="title wow animate__animated animate__fadeInUp">
          远程病理会诊系统
        </div>
        <div class="line"></div>
        <ul class="keywords wow animate__animated animate__fadeInUp">
          <li>灵活</li>
          <li>高效</li>
          <li>完整</li>
          <li>精准</li>
          <li>稳定</li>
        </ul>
      </div>
    </div>
    <div class="patholog-description">
      <div class="prod-single">
        <div class="title wow animate__animated animate__fadeInUp">
          会诊流程
        </div>
        <div class="prod-img">
          <img
            class="wow animate__animated animate__fadeInUp"
            src="@/assets/image/patholog/flow.png"
            alt=""
          />
        </div>
      </div>
      <div class="prod-double">
        <div class="title wow animate__animated animate__fadeInUp">界面</div>
        <div class="prod-img">
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/patholog/prod-1.png"
              alt=""
            />
            <div class="desc">登录界面</div>
          </div>
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/patholog/prod-2.png"
              alt=""
            />
            <div class="desc">会诊申请端</div>
          </div>
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/patholog/prod-3.png"
              alt=""
            />
            <div class="desc">会诊专家端</div>
          </div>
          <div class="img-box">
            <img
              class="wow animate__animated animate__fadeInUp"
              src="@/assets/image/patholog/prod-4.png"
              alt=""
            />
            <div class="desc">会诊管理端</div>
          </div>
        </div>
      </div>
      <div class="characteristic">
        <ul class="feature-box">
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">全流程解决方案</div>
              <div class="desc">
                从制片到出报告，产品全流程覆盖，兼容性好，故障率低，售后更方便
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">功能齐全</div>
              <div class="desc">
                具备制染、数字切片扫描分析、数据存储、远程会诊、教学、两癌信息管理等功能，进入市场早，用户多。
              </div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">方案成熟</div>
              <div class="desc">软件开发经验丰富，已有成功的类似案例</div>
            </div>
          </li>
          <li class="feature-item">
            <div class="left">
              <img
                class="wow animate__animated animate__fadeInUp"
                src="@/assets/image/patholog/icon-1.png"
                alt=""
              />
            </div>
            <div class="right wow animate__animated animate__fadeInUp">
              <div class="title">灵活度高</div>
              <div class="desc">多种不同通量设备，满足不同样本医院需求</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../../api/api";
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
import Breadcrumb from "../../../components/Breadcrumb.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    Breadcrumb,
    RelatedProduct,
  },
  data() {
    return {
      productType: "产品",
      productName: "远程病理会诊系统",
      relatProd: [
        {
          title: "全自动特殊染色机",
          src: require("@/assets/image/product/film-4.png"),
          path: "/product/special",
        },
        {
          title: "DNA定量分析诊断试剂",
          src: require("@/assets/image/product/reagent-2.png"),
          path: "/product/dnaReagent",
        },
        {
          title: "DNA倍体定量分析系统",
          src: require("@/assets/image/product/diagnosis-1.png"),
          path: "/product/dnaProd",
        },
        {
          title: "扫描通量600片",
          src: require("@/assets/image/product/digit-1.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量120片",
          src: require("@/assets/image/product/digit-2.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量30片",
          src: require("@/assets/image/product/digit-3.png"),
          path: "/product/gemini",
        },
        {
          title: "扫描通量2片",
          src: require("@/assets/image/product/digit-4.png"),
          path: "/product/gemini",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;

              console.log(this.relatProd);
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#patholog {
  padding-top: 6.5rem;
  .patholog-img {
    img {
      width: 100%;
    }
    .illustrate-box {
      padding: 1.25rem;
      .title {
        font-size: 1.5rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1.5rem;
      }
      .line {
        width: 4.25rem;
        height: 3px;
        margin-bottom: 1.25rem;
        background: #177a73;
      }
      .keywords {
        display: flex;
        li {
          width: 20%;
          height: 2rem;
          line-height: 2rem;
          margin-right: 4%;
          color: #fff;
          background: #177a73;
          border-radius: 2rem;
          text-align: center;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
  .patholog-description {
    padding: 1.25rem;
    width: 100%;
    box-sizing: border-box;
    background-size: cover;
    .prod-single {
      margin-bottom: 2.8rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
      }
    }
    .prod-double {
      margin-bottom: 2.8rem;
      .title {
        font-size: 1.2rem;
        font-family: "SourceHanSansSC-Medium";
        margin-bottom: 1rem;
      }
      .prod-img {
        width: 100%;
        .img-box {
          img {
            width: 100%;
            margin-bottom: 0.5rem;
          }
          .desc {
            text-align: center;
            font-size: 1rem;
            font-family: "OPlusSans3-Regular";
            margin-bottom: 1.5rem;
          }
        }
      }
    }
    .characteristic {
      margin: auto;
      .feature-box {
        .feature-item {
          display: flex;
          align-items: flex-start;
          width: 100%;
          margin-bottom: 3rem;
          .left {
            width: 15%;
            margin-right: 5%;
            img {
              padding-top: 0.4rem;
              width: 100%;
            }
          }
          .right {
            width: 80%;
            .title {
              font-size: 1.2rem;
              font-family: "SourceHanSansSC-Medium";
              margin-bottom: 1rem;
            }
            .desc {
              font-size: 1rem;
              font-family: "OPlusSans3-Regular";
            }
          }
        }
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>